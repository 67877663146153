@import "styles/fonts";
@import "styles/variables";
@import "styles/mixins";
@import "styles/titles";

// Animations
@import "styles/animations";

// Componentes
@import "styles/components/buttons";
@import "styles/components/links";
@import "styles/components/forms";
@import "styles/components/alerts";

// Home
@import "styles/home/header";
@import "styles/home/slider";
@import "styles/home/novedades";
@import "styles/home/app-mobile";
@import "styles/home/beneficios";

// Shared
@import "styles/datos-utiles";

// Login
@import "styles/login";

// Dashboard
@import "styles/dashboard/dashboard";
@import "styles/dashboard/header";
@import "styles/dashboard/accesos";
@import "styles/dashboard/productos";

// Drawer
@import "styles/drawer/main";
@import "styles/drawer/monto-transferir";
@import "styles/drawer/transferencia-exitosa";
@import "styles/drawer/verificar-cuenta";
@import "styles/drawer/transferencia-fallida";
@import "styles/drawer/select-destinatario";

// Backlog
@import "styles/backlog/layout";
@import "styles/backlog/login";
@import "styles/backlog/header";
@import "styles/backlog/tables";

// Footer
@import "styles/footer";

body {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: $color-font;
}

@media (min-width: 1200px) {
  .container {
    min-width: 1200px;
  }
}
