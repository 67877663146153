.section-title {
  font-family: "Dosis Bold", sans-serif;
  color: $color-title;
  text-transform: uppercase;
  font-size: 22px;
}

.text-danger {
  color: $text-danger !important;
}

.text-dark {
  color: $text-dark !important;
}

@media (min-width: 992px) {
  .section-title {
    font-size: 26px;
  }
}

@media (min-width: 1200px) {
  .section-title {
    font-size: 30px;
  }
}