#slider {
  padding-bottom: 4rem;

  img {
    border-radius: 10px;
  }
}

.carousel-indicators li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: 0.25rem;
  background: transparent;
  border: 1px solid #ffffff;
  transition: all 0.2s ease-in-out;

  &.active {
    background: #ffffff;
    transition: all 0.2s ease-in-out;
  }
}
