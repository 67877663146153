.form-group-custom {
  text-align: center;
  margin: 0 auto 2.75rem;
  max-width: 300px;

  label {
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .form-text {
    margin-top: .5rem;
  }

  .invalid-feedback {
    display: block;
    height: 18px;
  }
}

.form-control-custom {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #000000;
  text-align: center;
  padding: 2rem;

  &::placeholder {
    font-style: italic;
  }

  &:focus {
    outline: none;
    box-shadow: 0;
    box-shadow: none;
    border-bottom: 1px solid #000000;
  }
}

.form-control-importe {
  font-size: 40px;

  &::placeholder {
    font-style: normal;
  }
}

@media (max-width: 600px) {
  .form-group-custom {
    margin: 0 auto 2rem;
    max-width: 100%;

    label {
      font-size: 16px;
      margin-bottom: .5rem;
    }
  }

  .form-control-custom {
    padding: 1rem;
  }

  .form-control-importe {
    font-size: 30px;
  }

}