#beneficios {
  h2 {
    margin-bottom: 2rem;
  }

  .line-wrapper {
    position: relative;
    margin-bottom: 1.5rem;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: stretch;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    height: 32px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    span {
      cursor: pointer;
      margin-right: 3.5rem;
    }
  }

  .selected {
    color: $color-selected;
    border-bottom: 4px solid $color-line-selected;
    z-index: 2;
  }

  .bottom-line {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    border-bottom: 2px solid transparentize($color-line, 0.7);
  }

  img {
    border-radius: 10px;
  }
}

@media (max-width: 991.98px) {
  #beneficios {
    .col-img {
      margin-bottom: 1.5rem;
    }
  }
}

@media (min-width: 992px) {
  #beneficios {
    h2 {
      margin-bottom: 2.5rem;
    }

    .line-wrapper {
      margin-bottom: 2rem;
    }

    .line {
      font-size: 18px;
      height: 36px;

      span {
        margin: 0;
      }
    }

    .col-lg-12 {
      margin-top: 2rem;
    }
  }
}

@media (min-width: 1200px) {
  #beneficios {
    h2 {
      margin-bottom: 3rem;
    }

    .line {
      font-size: 20px;
      height: 40px;
    }
  }
}