#monto-transferir {
  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 4rem;
  }

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  #monto-transferir {
    ul {
      margin-bottom: 2rem;
    }

    li {
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }
}