.login {
  padding: 2.5rem 0 6rem;
  position: relative;
  min-height: calc(100vh - 78px);

  .logo {
    width: 140px;
  }

  .row {
    justify-content: center;
  }

  .col-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    text-align: center;
  }

  .col-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .spacer,
  .back-link {
    width: 44px;
  }

  h2 {
    font-size: 30px;
    font-family: "Dosis", sans-serif;
    margin-bottom: 1rem;

    b {
      font-family: "Dosis Bold", sans-serif;
    }

    span {
      color: $color-title;
    }
  }

  .card {
    border: none;
    padding: 2rem;
    border-radius: 10px;
    width: 100%;
  }

  .icon {
    margin-bottom: 1rem;
  }

  .form-title {
    font-size: 20px;
    margin-bottom: 2rem;
    display: block;
    text-align: center;
  }

  label {
    font-weight: bold;
    color: $color-label;
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 3rem;
  }

  .form-control {
    border-radius: 0;
    padding-left: 0;
    border-width: 0;
    border-bottom-width: 1px;

    &::placeholder {
      font-style: italic;
    }

    &:focus {
      box-shadow: none;
      outline: none;
      border-bottom-color: $color-label;
    }
  }

  form {
    width: 100%;
  }

  .btn-success {
    margin: 4rem auto 2rem;
    max-width: 60%;
  }

  .background-grey {
    position: absolute;
    width: 100%;
    height: 40vh;
    bottom: 0;
    background-color: #f6f6f6;
  }

  .go-registro {
    margin-left: 0.25rem;
    color: $color-link-success;
    font-weight: bold;
    font-size: 16px;
  }
}

.img-policia {
  width: 60%;
}

.form-group-image {
  text-align: center;

  label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 1rem;
      width: 126px;
      height: 126px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  input {
    display: none;
  }
}

@media (min-width: 768px) {
  .login {
    .logo {
      width: 200px;
    }
  }
}

@media (min-width: 992px) {
  .login {
    .logo {
      width: auto;
    }
  }
}

@media (max-width: 575.98px) {
  .login {
    padding: 1rem 0;
    min-height: calc(100vh - 50px);

    h2 {
      font-size: 20px;
    }

    .form-title {
      font-size: 16px;
    }

    .card {
      padding: 2rem 1rem;
    }

    .btn-success {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
      display: block;
      font-size: 16px;
    }
  }
}
