#dashboard-header {
  padding: 1.25rem 0 2rem;

  .logo {
    width: 140px;
  }

  .cuenta {
    display: flex;
    align-items: center;
    font-family: "Dosis", sans-serif;
    font-weight: bold;
    color: $color-title;
    font-size: 16px;

    span {
      display: block;
    }
  }

  .user-avatar {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
  }

  .log-out {
    width: 25px;
    margin-left: 0.75rem;
  }
}

@media (min-width: 576px) {
  #dashboard-header {
    .cuenta {
      span {
        display: block;
      }
    }

    .log-out {
      margin-left: 1.25rem;
    }
  }
}

@media (min-width: 768px) {
  #dashboard-header {
    padding: 2rem 0;

    .logo {
      width: 200px;
    }

    .log-out {
      margin-left: 2rem;
    }
  }
}

@media (min-width: 992px) {
  #dashboard-header {
    padding: 3rem 0;

    .logo {
      width: 100%;
    }

    .cuenta {
      font-size: 18px;
    }

    .log-out {
      margin-left: 3.5rem;
    }
  }
}
