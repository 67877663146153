#datos-utiles {
  padding: 3rem 0;

  h2 {
    margin-bottom: 0;
  }

  .col {
    &:not(:first-of-type) {
      padding-left: 0.75rem;
    }

    &:not(:last-of-type) {
      padding-right: 0.75rem;
    }
  }

  .col-12 {
    display: block;
  }

  .media {
    @extend .card-layout;
    padding: 1.6rem 1.5rem;
    font-size: 14px;
  }

  .card-title {
    font-size: 14px;
    color: $color-title;
    font-weight: 700;
  }

  .tel {
    font-weight: 700;
  }

  span span {
    color: $color-title;
  }
}

@media (max-width: 1199.98px) {
  #datos-utiles {
    .row {
      overflow-x: auto;
      flex-wrap: nowrap;
      padding: 1.25rem 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .col-12 {
      max-width: 45%;
    }
  }
}

@media (max-width: 767.98px) {
  #datos-utiles {
    .col-12 {
      max-width: 80%;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
}

@media (min-width: 992px) {
  #datos-utiles {
    padding: 4rem 0;

    h2 {
      margin-bottom: 1.25rem;
    }

    .media {
      font-size: 15px;
    }

    .card-title {
      font-size: 15px;
    }
  }
}

@media (min-width: 1200px) {
  #datos-utiles {
    padding: 5rem 0;

    h2 {
      margin-bottom: 1.5rem;
    }

    .media {
      padding: 1rem;
    }

    .tel {
      display: block;
    }
  }
}