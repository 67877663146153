#dashboard-accesos {
  padding: 2rem 0;

  .section-title {
    margin-bottom: 2rem;
    font-size: 16px;
  }

  .accesos-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 16px;
      font-family: "Dosis", sans-serif;
      color: $color-accesos;
      font-weight: bold;
      text-transform: uppercase;

      &:not(:last-of-type) {
        margin-bottom: 1.25rem;
      }

      img {
        width: 44px;
        height: 44px;
        margin-right: 1.5rem;
      }

      span {
        margin-top: 0.5rem;
      }
    }
  }
}

@media (min-width: 576px) {
  #dashboard-accesos {
    .accesos-list {
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        width: 50%;

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #dashboard-accesos {
    .section-title {
      font-size: 18px;
      margin-bottom: 2.5rem;
    }

    .accesos-list {
      > div {
        width: auto;
        margin-bottom: 0 !important;

        img {
          width: auto;
          height: auto;
          margin-right: 1rem;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  #dashboard-accesos {
    .section-title {
      font-size: 20px;
      margin-bottom: 3rem;
    }

    .accesos-list {
      > div {
        font-size: 20px;
      }
    }
  }
}
