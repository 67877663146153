.table-card {
  width: 100%;
  padding: 1rem 0 2rem;

  .table-header,
  .row-top,
  .row-bottom {
    display: flex;
  }

  .table-header {
    font-weight: bold;
    font-size: 14px;
    padding: 0.75rem 1.5rem;
  }

  .row-card {
    background-color: #f6f6f6;
    border-radius: 8px;

    &:not(:last-of-type) {
      margin-bottom: 0.75rem;
    }
  }

  .row-top {
    border-bottom: 1px solid #dedede;
    padding: 1.5rem;
  }

  .row-bottom {
    justify-content: space-between;
    color: #9f9f9f;
    padding: 1.5rem;
  }

  .table-fecha {
    width: 35%;
  }

  .table-usuario {
    width: 35%;
  }

  // .table-quality {
  //   width: 15%;
  // }

  // .table-similarity {
  //   width: 15%;
  // }

  .table-riesgo {
    width: 30%;
  }

  .table-riesgo-data {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &.success {
      color: #43ad4b;
    }

    &.danger {
      color: #dd2c00;
    }

    &.warning {
      color: #ffc107;
    }

    span:first-of-type {
      margin-right: auto;
    }
  }

  &.table-card-transferencias {
    .table-fecha {
      width: 25%;
    }

    .table-usuario {
      width: 25%;
    }

    .table-importe {
      width: 25%;
    }

    .table-riesgo {
      width: 25%;
    }
  }
}

@media (min-width: 768px) {
  .table-card {
    padding: 4rem 0 3rem;
  }
}

@media (min-width: 992px) {
  .table-card {
    padding: 6rem 0 4rem;
  }
}

@media (max-width: 991.98px) {
  .table-card {
    overflow-x: auto;

    .table-header,
    .row-card {
      width: 900px;
      font-size: 80%;
    }

    .row-top,
    .row-bottom {
      padding: 0.75rem 1rem;
    }
  }
}

@media (max-width: 600px) {
  .table-card {
    .table-header,
    .row-card {
      width: 800px;
    }
  }
}
