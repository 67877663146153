.alert {
  border-radius: 8px;
  font-size: 15px;
}

.alert-primary {
  text-align: center;
  // padding: 2rem 1.5rem;
  padding: 1rem;
  color: $color-alert-primary;
  border-color: $color-alert-primary;
  background-color: transparentize($color-alert-primary, 0.9);
}

.alert-success {
  color: $color-alert-success;
  border-color: $color-alert-success;
  background-color: $color-alert-success-bk;
}

.alert-login {
  padding: 1rem 2rem;
  position: fixed;
  bottom: 0;
  right: 1rem;
  z-index: 2;
  display: flex;
  align-items: flex-start;

  .alert-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0.25rem;
  }

  .alert-message {
    font-size: 14px;
    max-width: 80%;
  }

  img {
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  .alert {

    font-size: 18px;
  }

  .alert-primary {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 992px) {
  .alert {
    font-size: 20px;
  }
}