#header-home {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: bold;
  padding: .5rem 0;

  .logo {
    width: 140px;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .navbar-collapse {
    justify-content: center;
  }

  img {
    cursor: pointer;
  }

  .navbar-nav {
    text-align: center;
  }

  .nav-link {
    cursor: pointer;
    text-transform: uppercase;
    color: $color-link;

    &:hover {
      color: transparentize($color-link, 0.3);
    }
  }

  .navbar-toggler {
    border: 2px solid $color-link;
    padding: 0.25rem;

    .navbar-toggler-icon {
      width: 24px;
      height: 24px;
    }
  }

  .btn {
    width: 100%;
  }
}

@media screen and (min-width: 576px) and (max-width: 991.98px) {
  #header-home {
    .container {
      padding: 0 15px;
    }
  }
}

@media (min-width: 768px) {
  #header-home {
    padding: 1rem 0;

    .logo {
      width: 200px;
    }
  }
}

@media (min-width: 992px) {
  #header-home {
    padding: 2rem 0;

    .logo {
      width: auto;
    }

    .nav-link {
      padding: 0 1.7rem;
    }
  }
}