#backlog-header {
  padding-top: 1rem;
  background-color: $color-backlog-bg;

  .logo {
    width: 140px;
  }

  .container-top {
    margin-bottom: 1rem;
  }

  .log-out {
    width: 25px;
    margin-left: 4rem;
    cursor: pointer;
  }

  .links {
    display: flex;
    text-transform: uppercase;

    a {
      color: $color-backlog;
      text-decoration: none;
      padding: 0.75rem 1.5rem;
    }

    .current {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

@media (min-width: 768px) {
  #backlog-header {
    padding-top: 2rem;

    .logo {
      width: 200px;
    }

    .container-top {
      margin-bottom: 2rem;
    }
  }
}

@media (min-width: 992px) {
  #backlog-header {
    padding-top: 3rem;

    .logo {
      width: 100%;
    }

    .container-top {
      margin-bottom: 3.5rem;
    }
  }
}

@media (max-width: 600px) {
  #backlog-header {
    .links {
      justify-content: space-around;

      a {
        margin: 0;
        width: 50%;
        text-align: center;
        padding: 0.5rem 1rem;
        font-size: 15px;
      }
    }
  }
}
