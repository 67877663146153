#app-mobile {
  padding-top: 4rem;

  .bk-app-mobile {
    width: 100%;
    height: 100vw;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: #eceaeb;
  }

  .container {
    // margin-top: -10vw;
    margin-top: 1rem;
  }

  .card {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    border: none;
    border-radius: 10px;
    background-color: $mobile-bg-color;
    overflow: hidden;

    img,
    div {
      flex: 1 1 50%;
      width: 50%;
    }

    .content {
      padding: 2rem;
      color: $mobile-color;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .section-title {
      font-size: 24px;
      margin-bottom: 2rem;
      color: $mobile-color;
    }

    span {
      margin-bottom: 2rem;
    }

    .btn {
      font-size: 16px;
      width: 100%;
    }
  }
}

@media (max-width: 991.98px) {
  #app-mobile {
    .card {
      flex-direction: column;

      img,
      div {
        text-align: center;
        flex: 1 1 50%;
        width: 100%;
      }

      .content {
        align-items: center;
      }

      span {
        display: block;
      }
    }
  }
}

@media (min-width: 768px) {
  #app-mobile {

    .container {
      margin-top: -10vw;
    }

    .bk-app-mobile {
      height: 40vw;
    }

    .bk-app-mobile-mobile {
      display: none;
    }

    .card {
      .content {
        padding: 3rem;
        font-size: 18px;
      }

      .section-title {
        font-size: 28px;
      }

      .btn {
        width: auto;
      }
    }
  }
}

@media (max-width: 767.98px) {
  #app-mobile {
    .bk-app-mobile-mobile {
      display: block;
    }

    .bk-app-mobile-desktop {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  #app-mobile {
    .bk-app-mobile {
      height: 60vw;
    }

    .container {
      margin-top: -17vw;
    }

    .card {
      .content {
        padding: 2rem 3rem;
        font-size: 17px;
      }

      .section-title {
        font-size: 30px;
        margin-bottom: 8.5%;
      }

      span {
        margin-bottom: 8.5%;
      }
    }
  }
}

@media (min-width: 1200px) {
  #app-mobile {
    .card {
      .content {
        padding: 3rem;
        font-size: 20px;
        padding-right: 4rem;
      }

      .section-title {
        font-size: 35px;
        margin-bottom: 10%;
      }

      span {
        margin-bottom: 10%;
      }

    }
  }
}

@media (min-width: 1440px) {
  #app-mobile {
    .bk-app-mobile {
      height: 820px;
    }

    .container {
      margin-top: -225px;
    }
  }
}