// @import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;500;600;700;800&family=Open+Sans:wght@400;600;700;800&display=swap");

// font-family: 'Dosis', sans-serif;
// font-family: 'Open Sans', sans-serif;

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/dosis/Dosis-Regular.ttf");
}

@font-face {
  font-family: "Dosis Bold";
  font-style: bold;
  font-weight: 400;
  src: url("../fonts/dosis/Dosis-Bold.ttf");
}
