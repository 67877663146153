#novedades {
  padding-bottom: 3rem;

  h2 {
    margin-bottom: 0;
  }

  .card {
    border: none;
    @extend .card-layout;
    padding: 1.6rem 1.5rem;
    font-size: 14px;
  }

  p span {
    font-weight: bold;
    color: $color-selected;
  }

  .link {
    cursor: pointer;
    color: $color-link;
  }
}

@media (max-width: 991.98px) {
  #novedades {
    .row {
      overflow-x: auto;
      flex-wrap: nowrap;
      padding: 1.25rem 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .col-12 {
      max-width: 80%;
    }
  }
}

@media (min-width: 992px) {
  #novedades {
    padding-bottom: 4rem;

    h2 {
      margin-bottom: 1.25rem;
    }

    .card {
      padding: 2.25rem 2rem;
      font-size: 16px;
    }
  }
}

@media (min-width: 1200px) {
  #novedades {
    padding-bottom: 5rem;

    h2 {
      margin-bottom: 1.5rem;
    }
  }
}