#dashboard-productos {
  padding-top: 1rem;

  .section-title {
    font-size: 16px;
    margin-bottom: 2rem;
  }

  .col-card {
    margin-bottom: 1.25rem;
  }

  .card {
    @extend .card-layout;
    border: 0;
  }

  .card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    span {
      font-size: 9px;
    }

    .card-title {
      font-size: 16px;
      color: $color-title;
      font-weight: bold;
      margin: 0;
    }
  }

  .card-amount {
    line-height: 100%;
    font-size: 28px;
    font-weight: bold;
  }

  .card-link {
    color: $color-link;
    align-self: flex-end;
  }
}

@media (min-width: 992px) {
  #dashboard-productos {
    padding-top: 2rem;

    .section-title {
      font-size: 18px;
    }

    .col-card {
      margin-bottom: 0;
    }

    .card-top {
      span {
        font-size: 12px;
      }

      .card-title {
        font-size: 20px;
      }
    }

    .card-amount {
      font-size: 35px;
      line-height: auto;
      margin-bottom: 0.5rem;
    }
  }
}

@media (min-width: 1200px) {
  #dashboard-productos {
    padding-top: 3rem;

    .section-title {
      font-size: 20px;
    }
  }
}
