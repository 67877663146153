footer {
  font-size: 15px;
  color: $color-footer;
}

.footer-top {
  background-color: $color-bg-footer-top;
  padding: 4rem 0;

  img {
    margin-bottom: 4rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    letter-spacing: 1.07px;
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    &:hover:not(:first-of-type) {
      cursor: pointer;
      color: transparentize($color-footer, 0.3);
    }
  }

  .list-title {
    font-weight: 600;
  }
}

.footer-bottom {
  background-color: $color-bg-footer-bottom;
  display: flex;
  justify-content: center;
  padding: 1.75rem 0;
}

@media (max-width: 575.98px) {
  .footer-bottom {
    font-size: 0.8em;
    padding: 1rem;
    text-align: center;
  }
}
