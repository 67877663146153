.btn {
  font-size: 13px;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: none;
}

.btn-outline-primary {
  border: 3px solid $btn-border-color;
  color: $btn-color;

  &:hover {
    border: 3px solid $btn-border-color;
    background-color: $btn-bg-color;
    color: #ffffff;
  }
}

.btn-outline-light {
  border: 3px solid $btn-ol-border-color;
  color: $btn-ol-color;

  &:hover {
    border: 3px solid $btn-ol-border-color;
    background-color: $btn-ol-bg-color;
    color: #190cc1;
  }
}

.btn-success {
  font-family: "Dosis Bold", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background: #00c53a;
  padding: 0.65rem 4rem;
  border: 1px solid #00c53a;
  border-radius: 12px;
  box-shadow: none;
  width: 75%;
  font-size: 18px;
  display: block;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}

.btn-outline-dark {
  font-family: "Dosis Bold", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.65rem 4rem;
  border-radius: 12px;
  box-shadow: none;
  width: 60%;
  font-size: 18px;
  display: block;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  border: 2px solid #353535;

  &:hover {
    text-decoration: none;
  }
}
