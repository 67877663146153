#drawer {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparentize(#f6f6f6, 0.1);
  z-index: -1;
  transition: 150ms all ease-in;

  &.active {
    z-index: 0;
    opacity: 1;
    transition: 150ms all ease-out;
  }

  .drawer-card {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    width: 600px;
    transform: translateX(600px);
    transition: 200ms transform ease-in;

    &.active {
      transform: translateX(0);
      transition: 200ms transform ease-out;
    }
  }

  .drawer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #f6f6f6;

    span {
      font-size: 13px;
      font-weight: bold;
    }

    img {
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .drawer-content {
    padding: 3rem 2rem;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .drawer-title,
  .drawer-legend {
    text-align: center;
    color: $color-title-drawer;
  }

  .drawer-title {
    font-family: "Dosis", sans-serif;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .drawer-legend {
    font-size: 20px;
    margin-bottom: 3rem;
  }

  .section-title {
    font-size: 20px;
    margin-bottom: 2rem;
  }

  .card {
    @extend .card-layout;
    margin-bottom: 1rem;
    border: 0;
    height: auto;
    cursor: pointer;

    .destinatario-name {
      font-size: 16px;
      color: #00b1ce;
      margin-bottom: 0.25rem;
      font-weight: 400;
    }
  }

  .drawer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 2.5rem 2rem;
    background-color: #fff;
  }

  .btn-drawer {
    margin-top: auto;
  }
}

@media (max-width: 1440px) {
  #drawer {
    .drawer-card {
      width: 500px;
      transform: translateX(500px);
    }
  }
}

@media (max-width: 600px) {
  #drawer {
    .drawer-card {
      width: 100vw;
      transform: translateX(100vw);
    }

    .drawer-top {
      padding: 1rem;
    }

    .drawer-content {
      padding: 2rem 1rem;
      padding-bottom: 80px;
    }

    .drawer-bottom {
      padding: 1rem;
    }

    .drawer-title {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 2rem;
    }

    .drawer-legend {
      font-size: 16px;
      margin-bottom: 3rem;
    }

    .btn {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
      display: block;
      font-size: 16px;
    }
  }
}