#transferencia-exitosa {
  .drawer-title {
    font-weight: bold;
    color: $color-accesos;
  }

  img {
    display: block;
    width: 45%;
    margin: 2rem auto;
  }
}

@media (max-width: 600px) {
  #transferencia-exitosa {
    img {
      width: 40%;
    }
  }
}