#verificar-cuenta {
  .drawer-title {
    color: #dd2c00;
  }

  img {
    display: block;
    margin: 0 auto 3rem;
  }
}
