#backlog-layout {
  background-color: $color-backlog-bg;
  color: $color-backlog;
  min-height: 100vh;

  .login .col-header {
    justify-content: center;
  }

  h2 {
    font-weight: 400;
    margin-bottom: 30%;
  }

  label {
    font-weight: bold;
    color: $color-backlog;
  }

  .form-control {
    border-bottom: 1px solid $color-backlog;
  }

  input {
    background: transparent;
    color: $color-backlog;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
  }
}