#drawer #select-destinatario {
  padding-bottom: 150px;

  .destinatario-name,
  .destinatario-cbu {
    display: block;
  }

  .card {
    display: block;
  }
}